<template>
  <base-loader v-if="loading"></base-loader>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">
                      {{ $t("sidebar.generate_report") }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("sidebar.overall_report") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.type")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="reportType"
                        @change="chgReportType($event)"
                      >
                        <option value="" selected>
                          {{ $t("form.choose_type") }}
                        </option>
                        <option value="company">
                          {{ $t("form.company") }}
                        </option>
                        <option value="trader">
                          {{ $t("form.trader") }}
                        </option>
                        <option value="courier">
                          {{ $t("form.courier") }}
                        </option>
                        <option value="customer">
                          {{ $t("form.customer") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="col-lg-6"
                    v-if="reportType == 'company' || reportType == 'trader'"
                  >
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.choose")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="typeId"
                      >
                        <option value="0" selected>
                          {{ $t("form.choose") }}
                        </option>
                        <option
                          v-for="(item, index) in comps_trads"
                          :value="item.id"
                          :key="index"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6" v-else>
                    <base-input
                      alternative=""
                      :label="$t('form.add_id')"
                      :placeholder="$t('form.add_id')"
                      input-classes="form-control-alternative"
                      v-model="typeId"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.from')"
                      :placeholder="$t('form.from')"
                      input-classes="form-control-alternative"
                      type="date"
                      v-model="model.from_date"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.to')"
                      :placeholder="$t('form.to')"
                      input-classes="form-control-alternative"
                      type="date"
                      v-model="model.to_date"
                    />
                  </div>
                </div>
              </div>
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('form.generate_report')"
                @click.prevent="generateCustomReport()"
              />
            </form>
          </card>
        </div>
      </div>
      <div class="row mt-4" v-if="customReport.length > 0">
        <div class="col">
          <div
            class="card shadow"
            :class="type === 'dark' ? 'bg-default' : 'bg-default'"
          >
            <div
              class="card-header border-0"
              :class="type === 'dark' ? 'bg-default' : 'bg-default'"
            >
              <div class="row align-items-center justify-content-between">
                <div class="col">
                  <h3
                    class="mb-0"
                    :class="type === 'dark' ? 'text-white' : 'text-white'"
                  >
                    {{ $t("sidebar.overall_report") }}
                  </h3>
                </div>
                <vue-excel-xlsx
                  class="btn btn-primary btn-sm"
                  :data="excelData"
                  :columns="columns"
                  :file-name="'Report'"
                  :file-type="'xlsx'"
                  :sheet-name="'Report'"
                >
                  {{ $t("tables.export excel") }}
                </vue-excel-xlsx>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                class="table align-items-center table-flush"
                :class="type === 'dark' ? 'table-dark' : 'table-dark'"
                :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-dark'"
                tbody-classes="list"
                :data="customReport"
              >
                <!-- Table Head -->
                <template v-slot:columns>
                  <th>{{ $t("tables.order_id") }}</th>
                  <th>{{ $t("tables.order_date") }}</th>
                  <th>{{ $t("tables.receiver_name") }}</th>
                  <th>{{ $t("tables.receiver_phone") }}</th>
                  <th>{{ $t("tables.delivery_charge") }}</th>
                  <th>{{ $t("tables.status") }}</th>
                  <th>{{ $t("tables.sender_address") }}</th>
                  <th>{{ $t("tables.receiver_address") }}</th>
                  <th>{{ $t("tables.price") }}</th>
                  <th>{{ $t("tables.new_price") }}</th>
                  <th>{{ $t("tables.coupon_id") }}</th>
                  <th>{{ $t("tables.order_by") }}</th>
                  <th>{{ $t("tables.item_price") }}</th>
                  <th>{{ $t("tables.payment_method") }}</th>
                </template>
                <!-- Table Body -->
                <template v-slot:default="row">
                  <td class="budget">
                    {{ row.item.order_id }}
                  </td>
                  <td class="budget">
                    {{ row.item.order_date }}
                  </td>
                  <td class="budget">
                    {{ row.item.receiver_name }}
                  </td>
                  <td class="budget">
                    {{ row.item.receiver_phone }}
                  </td>
                  <td class="budget">
                    {{ row.item.delivery_charge }}
                  </td>
                  <td class="budget">
                    {{ row.item.status }}
                  </td>
                  <td class="budget wrap">
                    {{ row.item.sender_address }}
                  </td>
                  <td class="budget wrap">
                    {{ row.item.receiver_address }}
                  </td>
                  <td class="budget">
                    {{ row.item.price }}
                  </td>
                  <td class="budget">
                    {{ row.item.new_price }}
                  </td>
                  <td class="budget">
                    {{ row.item.coupon }}
                  </td>
                  <td class="budget">
                    {{ row.item.order_by }}
                  </td>
                  <td class="budget">
                    {{ row.item.item_price }}
                  </td>
                  <td class="budget">
                    {{ row.item.payment_Method }}
                  </td>
                </template>
              </base-table>
            </div>
            <div
              class="card-footer d-flex justify-content-end bg-transparent"
              :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <!-- Pagination Component -->
              <base-pagination
                :page="page"
                :total_pages="total_pages"
                v-on:pagination-num="page = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Generate-Report",
  data() {
    return {
      renderComponent: false,
      loading: false,
      validationErrors: [],
      reportType: "",
      typeId: "0",
      model: {
        from_date: "",
        to_date: "",
      },
      comps_trads: [],
      customReport: [],
      excelData: [],
      page: 1,
      total_pages: "",
      columns: [
        {
          label: "order_id",
          field: "order_id",
        },
        {
          label: "order_date",
          field: "order_date",
        },
        {
          label: "receiver_name",
          field: "receiver_name",
        },
        {
          label: "receiver_phone",
          field: "receiver_phone",
        },
        {
          label: "delivery_charge",
          field: "delivery_charge",
        },
        {
          label: "status",
          field: "status",
        },
        {
          label: "sender_address",
          field: "sender_address",
        },
        {
          label: "receiver_address",
          field: "receiver_address",
        },
        {
          label: "price",
          field: "price",
        },
        {
          label: "new_price",
          field: "new_price",
        },
        {
          label: "coupon_id",
          field: "coupon_id",
        },
        {
          label: "order_by",
          field: "order_by",
        },
        {
          label: "item_price",
          field: "item_price",
        },
        {
          label: "payment_method",
          field: "payment_Method",
        },
      ],
    };
  },
  watch: {
    page: function () {
      this.generateCustomReport(
        "admin/administration/reports/ordersDetails?page=" + this.page
      );
    },
  },
  methods: {
    // Change Report Type
    chgReportType: function () {
      // this.loading = true;
      if (event.target.value == "company" || event.target.value == "trader") {
        this.getArr(
          `admin/administration/${
            event.target.value == "company" ? "companies" : "traders"
          }/index`
        );
      }
      this.reportType = event.target.value;
    },
    getArr: function ($url) {
      this.loading = true;
      axios
        .get($url)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.comps_trads = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    generateCustomReport: function (
      $url = "admin/administration/reports/ordersDetails"
    ) {
      this.loading = true;
      this.validationErrors = [];
      axios
        .post($url, this.toFormData())
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.customReport = response.data.data.details;
              let totalObj = response.data.data.total;
              totalObj.order_id = `Total: ${response.data.data.total.orders}`;
              totalObj.order_date = `From: ${this.model.from_date}`;
              totalObj.receiver_name = `To: ${this.model.to_date}`;
              this.customReport.push(totalObj);
              this.customReport.from;
              this.excelData = this.customReport;
              this.page = response.data.meta.current_page;
              this.total_pages = response.data.meta.last_page;
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    toFormData: function () {
      var fd = new FormData();
      fd.append("type", this.reportType);
      if (this.reportType == "company") {
        fd.append("company_id", this.typeId);
      } else if (this.reportType == "trader") {
        fd.append("trader_id", this.typeId);
      } else if (this.reportType == "customer") {
        fd.append("customer_id", this.typeId);
      } else if (this.reportType == "courier") {
        fd.append("courier_id", this.typeId);
      }
      fd.append("from_date", this.model.from_date);
      fd.append("to_date", this.model.to_date);
      return fd;
    },
  },
};
</script>
<style scoped>
.wrap {
  white-space: initial;
}
</style>
